import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as serviceWorkerRegister from '../serviceWorkerRegistration';
import { useNewVersionStore } from 'src/shared/stores/useNewVersionToasterStore';

interface ContextData {
  assetsUpdateReady: boolean;
  assetsCached: boolean;
  updateAssets: () => Promise<void>;
  waitingServiceWorker: ServiceWorker | null;
  onUpdateAssetsFinish?: () => void;
}

const ServiceWorkerContext = createContext({} as ContextData);

function ServiceWorkerProvider({ children }: { children: React.ReactNode }) {
  const [waitingServiceWorker, setWaitingServiceWorker] =
    useState<ServiceWorker | null>(null);
  const [assetsUpdateReady, setAssetsUpdateReady] = useState(false);
  const [assetsCached, setAssetsCached] = useState(false);
  const { setHasPendingSuccessfullyUpdatedToaster } = useNewVersionStore();

  const value = useMemo(
    () => ({
      onUpdateAssetsFinish: () => {},
      waitingServiceWorker,
      assetsUpdateReady,
      assetsCached,

      updateAssets: () => {
        return new Promise<void>((resolve, reject) => {
          console.log(
            'updateAssets... waitingServiceWorker',
            waitingServiceWorker,
          );

          if (waitingServiceWorker) {
            // console.log('state atual:', waitingServiceWorker.state);

            waitingServiceWorker.addEventListener('statechange', (event) => {
              const worker = event.target as ServiceWorker;

              if (worker.state === 'activated') {
                setHasPendingSuccessfullyUpdatedToaster(true);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
                resolve();
              }
            });

            waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });

            setTimeout(() => {
              reject(
                new Error(
                  'Falha ao ativar o Service Worker ou a resposta demorou demais.',
                ),
              );
            }, 6000);
          } else {
            reject(
              new Error('Não foi encontrado um Service Worker aguardando.'),
            );
          }
        });
      },
    }),
    [assetsUpdateReady, assetsCached, waitingServiceWorker],
  );

  useEffect(() => {
    serviceWorkerRegister.register({
      onUpdate: (registration: ServiceWorkerRegistration) => {
        setWaitingServiceWorker(registration.waiting);
        setAssetsUpdateReady(true);
      },
      onSuccess: () => {
        setAssetsCached(true);
      },
    });
  }, []);

  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  );
}

function useServiceWorker() {
  const context = useContext(ServiceWorkerContext);

  if (!context) {
    throw new Error(
      'useServiceWorker must be used within a ServiceWorkerProvider',
    );
  }

  return context;
}

export { ServiceWorkerProvider, useServiceWorker };
