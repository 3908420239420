import create from 'zustand';
import { persist } from 'zustand/middleware';

export type NewVersionStoreData = {
  hasPendingSuccessfullyUpdatedToaster: boolean;
  setHasPendingSuccessfullyUpdatedToaster: (value: boolean) => void;
};

export const useNewVersionStore = create(
  persist(
    (set) => ({
      hasPendingSuccessfullyUpdatedToaster: false,
      setHasPendingSuccessfullyUpdatedToaster: (value: boolean) =>
        set((state: NewVersionStoreData) => ({
          ...state,
          hasPendingSuccessfullyUpdatedToaster: value,
        })),
    }),
    {
      name: 'useNewVersionStore',
    },
  ),
);
