import { Stitches } from 'src/styles/stitches';

const CommonStyles = {
  borderRadius: '$default',
  padding: '0 $2',
  width: '100%',
  fontWeight: '$medium',
  transition: 'background-color 0.3s',
  fontSize: '$body',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$2',
  cursor: 'pointer',
  whiteSpace: 'nowrap',

  '&:disabled': {
    cursor: 'not-allowed',
  },
};

const CommonButton = Stitches.styled('button', {
  ...CommonStyles,

  variants: {
    size: {
      small: {
        height: '$1',
        border: '2px solid',

        '& > svg': {
          width: '$iconSmall',
          height: '$iconSmall',
        },
      },
      medium: {
        height: '$2',

        '& > svg': {
          width: '$iconMedium',
          height: '$iconMedium',
        },
      },
      large: {
        height: '$3',
        fontSize: '$subtitle',

        '& > svg': {
          width: '$iconLarge',
          height: '$iconLarge',
        },
      },
    },
  },

  defaultVariants: {
    size: 'large',
  },
});
export const ButtonPrimaryWrapper = Stitches.styled(CommonButton, {
  '&:disabled': {
    background: '$gray100',
  },

  variants: {
    color: {
      primary: {
        background: '$primary',
        color: '$white',

        '&:not(:disabled):hover': {
          background: '$primaryDark',
        },

        '& > svg': {
          color: '$white',
        },
      },
      gray400: {
        background: '$gray400',
        color: '$white',

        '&:not(:disabled):hover': {
          background: '$gray300',
        },

        '& > svg': {
          color: '$white',
        },
      },
    },
  },

  defaultVariants: {
    color: 'primary',
  },
});
export const ButtonPrimaryWrapperPagination = Stitches.styled(CommonButton, {
  '&:disabled': {
    background: '#E9EDF3',
  },

  variants: {
    color: {
      primary: {
        background: '#E9EDF3',
        color: 'black',

        '&:not(:disabled):hover': {
          background: '$primaryDark',
        },

        '& > svg': {
          color: 'black',
        },
      },
      gray400: {
        background: '$gray400',
        color: '$white',

        '&:not(:disabled):hover': {
          background: '$gray300',
        },

        '& > svg': {
          color: '$white',
        },
      },
    },
  },

  defaultVariants: {
    color: 'primary',
  },
});

export const ButtonOutlinedWrapper = Stitches.styled(CommonButton, {
  border: '3px solid',
  background: 'transparent',

  '&:disabled': {
    borderColor: '$gray100',
    color: '$gray100',
    '& > svg': {
      color: '$gray100',
    },
  },

  variants: {
    color: {
      primary: {
        borderColor: '$primary',
        color: '$primary',

        '&:not(:disabled):hover': {
          borderColor: '$primaryDark',
          color: '$primaryDark',
          '& > svg': {
            color: '$primaryDark',
          },
        },

        '& > svg': {
          color: '$primary',
        },
      },
      gray400: {
        borderColor: '$gray400',
        color: '$gray400',

        '&:not(:disabled):hover': {
          borderColor: '$gray300',
          color: '$gray300',
          '& > svg': {
            color: '$gray300',
          },
        },

        '& > svg': {
          color: '$gray400',
        },
      },
      gray300: {
        borderColor: '$gray300',
        color: '$gray300',

        '&:not(:disabled):hover': {
          borderColor: '$gray400',
          color: '$gray400',
          '& > svg': {
            color: '$gray400',
          },
        },

        '& > svg': {
          color: '$gray300',
        },
      },
    },
    border: {
      disabled: {
        borderColor: '$gray80',
        borderWidth: '1px',
      },
    },
  },

  defaultVariants: {
    color: 'gray300',
  },
});

export const UploadFileButtonPrimaryWrapper = Stitches.styled('label', {
  ...CommonStyles,

  '& > input': {
    display: 'none',
  },

  variants: {
    color: {
      primary: {
        background: '$primary',
        color: '$white',

        '&:not(:disabled):hover': {
          background: '$primaryDark',
        },

        '& > svg': {
          color: '$white',
        },
      },
      gray400: {
        background: '$gray400',
        color: '$white',

        '&:not(:disabled):hover': {
          background: '$gray300',
        },

        '& > svg': {
          color: '$white',
        },
      },
      disabled: {
        background: '$gray100',
        color: '$white',
        cursor: 'not-allowed',
        '& > svg': {
          color: '$white',
        },
      },
    },
    size: {
      small: {
        height: '$1',
      },
      medium: {
        height: '$3',
      },
      large: {
        height: '$4',
        fontSize: '$subtitle',
      },
    },
  },

  defaultVariants: {
    color: 'primary',
    size: 'large',
  },
});
export const ButtonActionContainer = Stitches.styled('div', {
  display: 'flex',
});
