import { isExpired } from 'react-jwt';
import { CONSTANTS } from '../../constants';

const { storeAccessTokenKey, storeRefreshTokenKey } = CONSTANTS;

export class TokenServices {
  static getAccessToken(): string | null {
    const token = localStorage.getItem(storeAccessTokenKey);
    return token || null;
  }

  static setAccessToken(token: string): void {
    localStorage.setItem(storeAccessTokenKey, token);
  }

  static removeAccessToken(): void {
    localStorage.removeItem(storeAccessTokenKey);
  }

  static getRefreshToken(): string | null {
    const token = localStorage.getItem(storeRefreshTokenKey);
    return token || null;
  }

  static setRefreshToken(token: string): void {
    localStorage.setItem(storeRefreshTokenKey, token);
  }

  static removeRefreshToken(): void {
    localStorage.removeItem(storeRefreshTokenKey);
  }

  static isExpiredToken(): boolean {
    const token = localStorage.getItem(storeAccessTokenKey);

    if (!token) return true;

    const isExpiredStoragedToken = isExpired(token);
    return isExpiredStoragedToken;
  }
}
