import { Stitches } from 'src/styles/stitches';

export const Title = Stitches.styled('h2', {
  display: 'flex',
  alignItems: 'center',
  color: '$gray400',
  textAlign: 'left',
  marginTop: '0.5rem',

  variants: {
    size: {
      small: {
        fontSize: '$titleSecondary',
      },
      medium: {
        fontSize: '$title',
      },
      large: {
        fontSize: '$main',
      },
    },
    weight: {
      medium: {
        fontWeight: '$medium',
      },
      bold: {
        fontWeight: '$bold',
      },
    },
  },

  defaultVariants: {
    size: 'large',
    weight: 'bold',
  },
});
