export const ptBRAccessControlPageTranslation = {
  accessControl: {
    title: 'Controle de acesso',
    title_add_user: 'Novo usuário',
    subtitle:
      'Gerencie permissões, credenciais e opções de segurança dos usuários.',
    subtitle_add_user: 'Informações',
    tabs: {
      users: 'Usuários',
      security: 'Segurança',
    },
    twoFactorAuth: {
      title: 'Autenticação em 2 fatores',
      description:
        'A autenticação em 2 fatores adiciona uma camada extra de segurança à sua conta SmrtComp exigindo um código de acesso ao realizar o login. Ou seja, será necessário a senha e um código de verificação para ser liberado o acesso.',
      allUsers:
        'Ao ativá-la, todos os usuários serão obrigados a usar a autenticação em 2 fatores para acessar o sistema.',
      enabled:
        'A autenticação em 2 fatores está ativada. Acione o suporte caso precise desativar.',
      notEnabled:
        'Após ativar a autenticação em 2 fatores, será necessário acionar o suporte caso precise desativar.',
    },
    howItWorks: {
      title: 'Como funciona?',
      step1:
        '1. Baixe um aplicativo de autenticação no seu dispositivo, como <google>Google Authenticator</google>, <microsoft>Microsoft Authenticator</microsoft> ou <authy>Authy</authy>.',
      step2:
        '2. Prossiga as etapas de validação do app em conjunto com a Smrt e finalize o processo de configuração.',
    },
    actions: {
      enableTwoFactor: 'Ativar autenticação em 2 fatores',
    },
    modal: {
      title: 'Ativar autenticação em 2 fatores?',
      description:
        'Após ativar a autenticação em 2 fatores, é necessário acionar o suporte caso precise desativar.',
    },
    newUser: {
      text: 'As credenciais, juntamente com as instruções de acesso serão enviadas para o e-mail cadastrado do usuário nesta tela. Certifique-se de preenche-lo corretamente para evitar acesso de pessoal não autorizado ao sistema.',
      permissions: 'Permissões',
    },
  },
};
