import { ReactNode } from 'react';

import { ButtonOutlined, ButtonPrimary } from 'src/components/buttons';
import { ModalDefaultWrapper } from 'src/components/modal-wrapper';
import { Title } from 'src/components/titles';
import {
  ActionConfirmModalWrapper,
  ActionConfirmModalWrapperContent,
  ActionConfirmModalActions,
  Description,
} from './styles';

type ActionConfirmModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonText?: string;
  isLoading?: boolean;
  children?: ReactNode;
  title: string;
  description: string;
};

export function ActionConfirmModal({
  isOpen,
  closeModal,
  onConfirm = () => ({}),
  onCancel = () => ({}),
  isLoading = false,
  children,
  title,
  description,
  confirmButtonText = 'Confirmar',
}: ActionConfirmModalProps) {
  const handleConfirm = () => onConfirm();
  const handleCancel = () => {
    closeModal();
    onCancel();
  };

  return (
    <ModalDefaultWrapper isOpenModal={isOpen} closeModal={closeModal}>
      <ActionConfirmModalWrapper>
        <Title size="small">{title}</Title>
        {description && (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        )}

        <ActionConfirmModalWrapperContent>
          {children}
        </ActionConfirmModalWrapperContent>
        <ActionConfirmModalActions>
          <ButtonOutlined
            size="medium"
            label="Cancelar"
            onClick={handleCancel}
          />
          <ButtonPrimary
            size="medium"
            label={confirmButtonText}
            isLoading={isLoading}
            onClick={handleConfirm}
          />
        </ActionConfirmModalActions>
      </ActionConfirmModalWrapper>
    </ModalDefaultWrapper>
  );
}
