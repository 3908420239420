import { Stitches } from 'src/styles/stitches';

export const ModalDefaultWrapperHeader = Stitches.styled('header', {
  width: '100%',
  minWidth: '380px',
  display: 'flex',
  // justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '$4',
  marginBottom: '$2',

  '& > button': {
    width: '$iconLarge',
    height: '$iconLarge',
    fontSize: 0,
    backgroundColor: 'transparent',
    borderRadius: '$default',
    transition: 'all 0.2s ease-in-out',
  },

  '& > button svg': {
    width: '$iconMedium',
    height: '$iconMedium',
    color: '$gray400',
  },

  variants: {
    hasTitle: {
      true: {
        justifyContent: 'space-between',
      },
      false: {
        justifyContent: 'flex-end',
      },
    },
  },
});

export const ModalDefaultWrapperContent = Stitches.styled('div', {
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
});
