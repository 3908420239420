import { ptBRPagesTranslation } from './pages';

export const translationPTBR = {
  common: {
    filters: {
      table_salary: 'Tabela Salarial',
      unit: 'Unidade',
      director: 'Diretoria',
      area: 'Área',
      grade_level: 'Nível',
      contract_type: 'Contrato',
      employee_code: 'Código funcionário',
    },
    actions: {
      search: 'Buscar',
      apply: 'Aplicar',
      edit: 'Editar',
      create: 'Criar',
      save: 'Salvar',
      delete: 'Deletar',
      remove: 'Excluir',
      clear: 'Limpar',
      download: 'Baixar',
      cancel: 'Cancelar',
      create_user: 'Criar usuário',
      confirm: 'Sim, continuar!',
    },
  },
  pages: {
    ...ptBRPagesTranslation,
  },
};
