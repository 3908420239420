import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './@externals';
// import { register } from './serviceWorkerRegistration';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// register({});

// register({
//   onUpdate: async (registration) => {
//     // We want to run this code only if we detect a new service worker is
//     // waiting to be activated.
//     // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
//     console.log('entrou no onUpdate...');
//     if (registration && registration.waiting) {
//       await registration.unregister();
//       // Makes Workbox call skipWaiting()
//       console.log('fazendo unregister...');
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//       // Once the service worker is unregistered, we can reload the page to let
//       // the browser download a fresh copy of our app (invalidating the cache)
//       window.location.reload();
//     }
//   },
// });
