import { Stitches } from 'src/styles/stitches';

export const NewVersionALertContainer = Stitches.styled('div', {
  width: '28rem',
  position: 'fixed',
  right: '2rem',
  bottom: '2rem',
  zIndex: 9999999999,
  background: '#fffef9',
  padding: '1rem 2rem 1rem 2rem',
  display: 'flex',
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  borderRadius: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2rem',
});
