import { Stitches } from 'src/styles/stitches';

export const ActionConfirmModalWrapper = Stitches.styled('div', {
  width: '100%',
  maxWidth: '500px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ActionConfirmModalWrapperContent = Stitches.styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '$6 0',
});

export const ActionConfirmModalActions = Stitches.styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$3',
});

export const Description = Stitches.styled('span', {
  color: '$gray400',
  textAlign: 'center',
  marginTop: '$5',
});
