// en-US.js
export const translationEN = {
  common: {
    filters: {
      table_salary: 'Table salary',
      unit: 'Unit',
      director: 'Director',
      area: 'Area',
      grade_level: 'Grade level',
      contract_type: 'Contract type',
      employee_code: 'Employee code',
    },
    actions: {
      search: 'Search',
      apply: 'Apply',
      edit: 'Edit',
      create: 'Create',
      save: 'Save',
      delete: 'Delete',
      remove: 'Remove',
      clear: 'Clear',
      download: 'Download',
      cancel: 'Cancel',
      confirm: 'Yes, continue!',
    },
  },
  pages: {
    meritocracy: {
      actions: {
        download: 'Export simulation to excel',
        only_simulated: 'Only simulated',
      },
      simulation: {
        title: 'Meritocracy',
        description: 'Select or create a new scenario to make simulations',
      },
      recommendation: {
        title: 'Meritocracy Recommendation',
      },
    },
    accessControl: {
      title: 'Access Control',
      subtitle: 'Manage user permissions, credentials, and security options.',
      tabs: {
        users: 'Users',
        security: 'Security',
      },
      twoFactorAuth: {
        title: 'Two-Factor Authentication',
        description: 'Two-factor authentication adds an extra layer of security to your SmrtComp account by requiring a passcode to log in. This means you’ll need both your password and a verification code to gain access.',
        allUsers: 'When enabled, all users will be required to use two-factor authentication to access the system.',
        enabled: 'Two-factor authentication is enabled. Contact support if you need to disable it.',
        notEnabled: 'After enabling two-factor authentication, you will need to contact support if you need to disable it.',
      },
      howItWorks: {
        title: 'How does it work?',
        step1: '1. Download an authentication app on your device, such as <google>Google Authenticator</google>, <microsoft>Microsoft Authenticator</microsoft>, or <authy>Authy</authy>.',
        step2: '2. Follow the app validation steps in conjunction with Smrt and complete the configuration process.',
      },
      actions: {
        enableTwoFactor: 'Enable Two-Factor Authentication',
      },
      modal: {
        title: 'Enable Two-Factor Authentication',
        description: 'Are you sure you want to enable two-factor authentication? This will require all users to set up the feature the next time they log in.',
      },
    },
  },
};
