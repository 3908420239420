/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { HttpStatus } from 'src/enums/http-status';
import { CONSTANTS } from '../../constants/index';
import { TokenServices } from '../token-services';
import toast from 'react-hot-toast';

const { API_BACKEND } = CONSTANTS;

const USER_TOKEN = TokenServices.getAccessToken();
const AuthStr = USER_TOKEN
  ? { Authorization: 'Bearer '.concat(USER_TOKEN) }
  : undefined;

export const API = axios.create({
  baseURL: API_BACKEND,
  headers: AuthStr,
});

export const BASE_API = axios.create({
  baseURL: API_BACKEND,
});

export async function autoUpdateRefreshToken() {
  const refreshToken = TokenServices.getRefreshToken();

  if (!refreshToken) {
    window.location.href = '/';
    return;
  }

  const result = await API.post('/users/auth/refresh', {
    token: refreshToken,
  });

  const { token, refresh_token } = result.data;
  TokenServices.setAccessToken(token);
  TokenServices.setRefreshToken(refresh_token);
}

API.interceptors.request.use((req) => {
  if (req.headers) {
    req.headers.Authorization = `Bearer ${TokenServices.getAccessToken()}`;
  }
  return req;
});

API.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (axios.isCancel(err)) {
      throw new axios.Cancel('Operation canceled by the user.');
    }
    const originalConfig = err.config;

    if (err.response) {
      if (
        err.response.status === HttpStatus.UNAUTHORIZED &&
        !originalConfig._retry &&
        originalConfig.url !== '/users/auth/refresh'
      ) {
        originalConfig._retry = true;

        try {
          await autoUpdateRefreshToken();
          return API(originalConfig);
        } catch (_error) {
          localStorage.clear();
          window.location.href = '/';
          return Promise.reject(_error);
        }
      }

      // if (err.response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
      //   toast.error("Houve um erro interno no sistema, nossa equipe estará verificando e solucionando este problema.");
      // }
    }

    return Promise.reject(err);
  },
);
