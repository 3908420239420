import React from 'react';
import { FiX } from 'react-icons/fi';
import Modal from 'react-modal';

import {
  ModalDefaultWrapperContent,
  ModalDefaultWrapperHeader,
} from './styles';
import { Title } from '../titles';

Modal.setAppElement('#modal');

type ModalDefaultWrapperProps = {
  children: React.ReactNode;
  title?: string;
  isOpenModal: boolean;
  closeModal: () => void;
  className?: string | '';
  style?: React.CSSProperties | undefined;
};

export function ModalDefaultWrapper({
  children,
  title,
  closeModal,
  isOpenModal,
  className,
  style,
}: ModalDefaultWrapperProps) {
  return (
    <Modal
      className={className ? className : 'modal-default-wrapper'}
      overlayClassName="modal-default-wrapper-overlay"
      isOpen={isOpenModal}
      onRequestClose={(e) => {
        e.stopPropagation();
        closeModal();
      }}
      contentLabel="Example Modal"
      portalClassName="modal-default-wrapper-portal"
      shouldFocusAfterRender={false}
      style={{
        content: style,
      }}
    >
      <ModalDefaultWrapperHeader hasTitle={!!title}>
        {title && <Title size="small">{title}</Title>}
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        >
          <FiX />
        </button>
      </ModalDefaultWrapperHeader>
      <ModalDefaultWrapperContent>{children}</ModalDefaultWrapperContent>
    </Modal>
  );
}
