import { Alert, Slide } from '@mui/material';
import { ButtonPrimary } from '../buttons';
import { FiAlertTriangle } from 'react-icons/fi';
import { NewVersionALertContainer } from './styles';

interface NewVersionALertProps {
  show: boolean;
  isLoading: boolean;
  handleUpdateAssets: () => void;
}

export function NewVersionALert({
  show,
  isLoading,
  handleUpdateAssets,
}: NewVersionALertProps) {
  return (
    <Slide direction="up" in={show} mountOnEnter unmountOnExit>
      <NewVersionALertContainer>
        <div>
          <FiAlertTriangle color="#ef9653" size={30} />
        </div>
        <p>Uma nova versão da plataforma está disponível.</p>
        <ButtonPrimary
          isLoading={isLoading}
          disabled={isLoading}
          label="Atualizar"
          size="medium"
          css={{ maxWidth: 'fit-content', zIndex: 9999, cursor: 'pointer' }}
          onClick={handleUpdateAssets}
        />
      </NewVersionALertContainer>
    </Slide>
  );
}
