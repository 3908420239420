import { useEffect, useState } from 'react';
import { useServiceWorker } from './service-worker-provider';
import { NewVersionALert } from 'src/components/new-version-alert';
import toast from 'react-hot-toast';
import { useNewVersionStore } from 'src/shared/stores/useNewVersionToasterStore';

export function NewVersionToasterProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [shouldShowToaster, setShouldShowToaster] = useState(false);
  const [isLoadingDispatch, setIsLoadingDispatch] = useState(false);
  const {
    hasPendingSuccessfullyUpdatedToaster,
    setHasPendingSuccessfullyUpdatedToaster,
  } = useNewVersionStore();

  const { assetsUpdateReady, updateAssets, waitingServiceWorker } =
    useServiceWorker();

  useEffect(() => {
    const checkForUpdates = async () => {
      const registrations = await navigator.serviceWorker.getRegistrations();
      let hasSomeWaiting = false;
      registrations.forEach((registration) => {
        if (registration.waiting) {
          console.log('Exists some serviceWork waiting...');
          hasSomeWaiting = true;
        }
      });

      if (!hasSomeWaiting) {
        if (navigator.serviceWorker) {
          navigator.serviceWorker.ready.then((registration) => {
            if (
              !registration.waiting &&
              !isLoadingDispatch &&
              !hasPendingSuccessfullyUpdatedToaster
            ) {
              console.log('Checking if serviceWork has update...');
              registration.update();
            }
          });
        }
      }
    };

    const intervalId = setInterval(() => {
      checkForUpdates();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [isLoadingDispatch, hasPendingSuccessfullyUpdatedToaster]);

  useEffect(() => {
    const checkForWaitingServiceWorker = async () => {
      const registrations = await navigator.serviceWorker.getRegistrations();

      registrations.forEach((registration) => {
        console.log('Checking if serviceWork is waiting...');
        if (registration.waiting) {
          console.log('ServiceWork is waiting and will be activeted...');
          registration.waiting.addEventListener('statechange', (event) => {
            const worker = event.target as ServiceWorker;
            if (worker.state === 'activated') {
              setHasPendingSuccessfullyUpdatedToaster(true);
              window.location.reload();
            }
          });

          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
      });
    };

    checkForWaitingServiceWorker();
  }, []);

  useEffect(() => {
    if (hasPendingSuccessfullyUpdatedToaster) {
      const intervalId = setTimeout(() => {
        toast.success('Sistema atualizado com sucesso!');
      }, 2000);
      setHasPendingSuccessfullyUpdatedToaster(false);
      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (assetsUpdateReady && waitingServiceWorker) {
      setShouldShowToaster(true);
    }
  }, [assetsUpdateReady, waitingServiceWorker]);

  return (
    <>
      <NewVersionALert
        show={shouldShowToaster}
        isLoading={isLoadingDispatch}
        handleUpdateAssets={() => {
          setIsLoadingDispatch(true);
          updateAssets().then(() => {
            setIsLoadingDispatch(false);
          });
        }}
      />
      {children}
    </>
  );
}
