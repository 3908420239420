import {
  ReactNode,
  useRef,
  useState,
  ChangeEvent,
  useEffect,
  useCallback,
} from 'react';
import toast from 'react-hot-toast';
import { SelectedRemunerationUpload } from 'src/modules/central-uploads/components/job-structure-uploads';
import { HtmlKeyType } from 'src/modules/central-uploads/components/upload-button-action';
import { ResponseEmployeesType } from 'src/modules/employees-data';
import { ResponseMatrixPositionsType } from 'src/modules/load-from-to';
import { ResponseSalariesType } from 'src/modules/salary-scale';
import { API } from 'src/shared/services/axios';
import { NominalPaperHistory } from 'src/shared/types';

import { ActionConfirmModal } from '../action-confirm-modal';
import { LoadingSpinner } from '../spinners';
import {
  ButtonPrimaryWrapper,
  ButtonOutlinedWrapper,
  UploadFileButtonPrimaryWrapper,
  ButtonPrimaryWrapperPagination,
} from './styles';

type ButtonPrimaryDefaultProps = typeof ButtonPrimaryWrapper.defaultProps;
type ButtonOutlinedDefaultProps = typeof ButtonOutlinedWrapper.defaultProps;
type UploadFileButtonPrimaryDefaultProps =
  typeof UploadFileButtonPrimaryWrapper.defaultProps;

type CommonButtonProps = {
  label: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  isLoading?: boolean;
};

type ButtonPrimaryProps = ButtonPrimaryDefaultProps & CommonButtonProps;
type ButtonOutlinedProps = ButtonOutlinedDefaultProps & CommonButtonProps;
type UploadFileButtonPrimaryProps = UploadFileButtonPrimaryDefaultProps &
  CommonButtonProps & {
    htmlForKey: HtmlKeyType;
    onConfirm: (file: File | null) => void;
    isDisabled?: boolean;
    selectedRemunerationUpload?: SelectedRemunerationUpload;
  };

export function ButtonPagination({
  label,
  startIcon = null,
  endIcon = null,
  isLoading = false,
  ...props
}: ButtonPrimaryProps) {
  return (
    <ButtonPrimaryWrapperPagination
      {...props}
      disabled={isLoading || props.disabled ? true : false}
    >
      {!isLoading ? (
        <>
          {startIcon} {label} {endIcon}
        </>
      ) : (
        <LoadingSpinner size="small" color="white" />
      )}
    </ButtonPrimaryWrapperPagination>
  );
}

export function ButtonPrimary({
  label,
  startIcon = null,
  endIcon = null,
  isLoading = false,
  ...props
}: ButtonPrimaryProps) {
  return (
    <ButtonPrimaryWrapper
      {...props}
      disabled={isLoading || props.disabled ? true : false}
    >
      {!isLoading ? (
        <>
          {startIcon} {label} {endIcon}
        </>
      ) : (
        <LoadingSpinner size="small" color="white" />
      )}
    </ButtonPrimaryWrapper>
  );
}

export function UploadFileButtonPrimary({
  label,
  startIcon = null,
  endIcon = null,
  isLoading = false,
  htmlForKey,
  onConfirm,
  selectedRemunerationUpload,
  isDisabled = false,
  ...props
}: UploadFileButtonPrimaryProps) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [fileSelected, setFileSelected] = useState<File | null>(null);
  const [fileName, setFileName] = useState('');
  const [nominalPaperFound, setNominalPaperFound] =
    useState<NominalPaperHistory>();
  const ref = useRef<HTMLInputElement>(null);
  const [description, setDescription] = useState('');
  const [existsTableSalaries, setExistsTableSalaries] = useState(false);
  const [existsPositions, setExistsPositions] = useState(false);
  const [existsEmployees, setExistsEmployees] = useState(false);

  const handleCheckNominalPaperAlreadyExists = async () => {
    if (htmlForKey === 'remuneration_template') {
      try {
        const month = selectedRemunerationUpload
          ? `?month=${selectedRemunerationUpload.month}`
          : '';
        const year = selectedRemunerationUpload
          ? `&year=${selectedRemunerationUpload.year}`
          : '';
        const response = await API.get<NominalPaperHistory>(
          `/tenants/nominal-paper${month}${year}`,
        );
        if (response.data) {
          setNominalPaperFound(response.data);
        }
      } catch (err) {
        toast.error('Erro ao verificar se já existe folha no mês!');
      }
    }
  };

  const handleAsyncFileSelection = async (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedRemunerationUpload && selectedRemunerationUpload.month) {
      await handleCheckNominalPaperAlreadyExists();
    }

    if (e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFileSelected(file);
      setFileName(file.name);
      setIsOpenModal(true);
      if (ref.current) {
        ref.current.value = '';
      }
    }
  };

  const title = 'Deseja fazer o upload do arquivo?';

  const handleCheckExistsTableSalaries = useCallback(async () => {
    const response = await API.get<ResponseSalariesType>(
      '/tenants/positions/grades/salaries',
      {
        params: {
          limit: 1,
          page: 1,
        },
      },
    );

    setExistsTableSalaries(!!response.data.data.length);
  }, []);

  const handleCheckExistsPositions = useCallback(async () => {
    const response = await API.get<ResponseMatrixPositionsType>(
      '/tenants/positions/match',
      {
        params: {
          limit: 1,
          page: 1,
        },
      },
    );

    setExistsPositions(!!response.data.data.length);
  }, []);

  const handleCheckExistsEmployees = useCallback(async () => {
    const response = await API.get<ResponseEmployeesType>(
      '/tenants/employees',
      {
        params: {
          limit: 1,
          page: 1,
        },
      },
    );

    setExistsEmployees(!!response.data.data.length);
  }, []);

  useEffect(() => {
    let description = '';

    if (htmlForKey === 'salary_table_template' && existsTableSalaries) {
      description =
        'A ação irá atualizar os dados das tabelas existentes. <br><br> <span style="color: red">OBS: Os valores serão arredondados!</span>';
    }

    if (htmlForKey === 'matching_positions_template' && existsPositions) {
      description = 'A ação irá atualizar os dados dos cargos existentes.';
    }

    if (htmlForKey === 'remuneration_template' && existsEmployees) {
      description =
        'A ação irá atualizar os dados dos funcionários existentes.';

      if (nominalPaperFound) {
        description = `${description} E a folha de <strong>mês: ${nominalPaperFound.month} / ano: ${nominalPaperFound.year}</strong>`;
      }
    }

    setDescription(description);
  }, [
    existsTableSalaries,
    existsPositions,
    existsEmployees,
    nominalPaperFound,
  ]);

  useEffect(() => {
    if (htmlForKey === 'salary_table_template') {
      handleCheckExistsTableSalaries();
    }
    if (htmlForKey === 'matching_positions_template') {
      handleCheckExistsPositions();
    }
    if (htmlForKey === 'remuneration_template') {
      handleCheckExistsEmployees();
    }
  }, [existsTableSalaries, existsPositions, existsEmployees]);

  const handleResetData = () => {
    setDescription('');
    setExistsPositions(false);
    setExistsTableSalaries(false);
    setExistsEmployees(false);
  };

  useEffect(() => {
    if (!isLoading && isOpenModal) setIsOpenModal(false);
  }, [isLoading]);

  return (
    <UploadFileButtonPrimaryWrapper
      {...props}
      color={isDisabled ? 'disabled' : props.color}
      htmlFor={htmlForKey}
    >
      {!isLoading && (
        <>
          {startIcon} {label} {endIcon}
          <input
            disabled={isDisabled}
            type="file"
            id={htmlForKey}
            onChange={handleAsyncFileSelection}
            ref={ref}
          />
        </>
      )}

      {isLoading && (
        <>
          Fazendo upload do arquivo...
          <LoadingSpinner size="small" color="white" />
        </>
      )}

      <ActionConfirmModal
        isOpen={isOpenModal}
        closeModal={() => {
          handleResetData();
          setIsOpenModal(false);
        }}
        onConfirm={() => onConfirm(fileSelected)}
        title={title}
        description={description}
        isLoading={isLoading}
      >
        <p style={{ color: '#3684B4' }}>{fileName}</p>
      </ActionConfirmModal>
    </UploadFileButtonPrimaryWrapper>
  );
}

export function ButtonOutlined({
  label,
  startIcon = null,
  endIcon = null,
  isLoading = false,
  ...props
}: ButtonOutlinedProps) {
  return (
    <ButtonOutlinedWrapper {...props} disabled={isLoading ? true : false}>
      {!isLoading ? (
        <>
          {startIcon} {label} {endIcon}
        </>
      ) : (
        <LoadingSpinner size="small" color="primary" />
      )}
    </ButtonOutlinedWrapper>
  );
}
